input.custom__input {
    background-image: url("./assets/shapes_svg/input/body2.svg");
    background-size:100% 100%;
    /* width:150px; */
    height:25px;
    border:none;
    outline:none;
    padding:15px;
    color:rgb(75, 75, 75);
    font-family: 'SpockPro';
    font-size:14px;
    background-color: transparent;
}

input.custom__input:focus{
    border:none;
    outline:none;
}

textarea.custom__input {
    background-image: url("./assets/shapes_svg/input/body.svg");
    background-size:100% 100%;
    /* width:150px; */
    /* height:25px; */
    border:none;
    outline:none;
    padding:10px;
    color:rgb(75, 75, 75);
    font-family: 'SpockPro';
    /* margin-left:10px; */
    background-color: transparent;
}

textarea.custom__input:focus{
    border:none;
    outline:none;
}

.testing {
    background-image:url("./assets/shapes_svg/username/hello - Copy.svg");
    background-size:100% 100%;
    height:100%;
    padding:10px 15px 20px 15px;
  }
  