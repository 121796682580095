:root {
    --editrow:100px;
}


.companydetail__edit__row{
    min-width:var(--editrow);
    align-items: center;
    display: flex;
}

.companydetail__edit__row > img{
    width:50%;
    cursor: pointer;
}

.companydetail__delete {
    background-image:url("./assets/shapes_svg/roledetail/delete.svg");
    background-size: 100% 100%;
    width:32%;
    height:40px;
    display: flex;
    flex-direction: column;
    align-items: center;    
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.companydetail__delete h4{
    margin-top:17px;    
    font-weight:400;
    font-size:14px;
    color:#F2F8F0;
}

.companydetail__delete img{
    /* margin-top:8px; */
    /* width:30% !important; */
    height:50%;
}

.delete__style2 {
    background-image:url("./assets/shapes_svg/roledetail/delete2.svg");
    background-size: 100% 100%;
}

.delete__style3 {
    background-image:url("./assets/shapes_svg/roledetail/delete3.svg");
    background-size: 100% 100%;
}

.companydetail__delete .MuiSvgIcon-root {
    color:#F2F8F0;
}