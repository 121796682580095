
.profile__body {
    background-image: url("../../assets/shapes_svg/profile/profilemain.svg");
    background-size:100% 100%;
}

.bg__box1 {
    background-image:url('../../assets/shapes_svg/profile/box2.svg');
    background-size:100% 100%;
}

.aboutabout td{
    border: 1px solid black;
}