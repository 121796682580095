.feed__main {
    background-image:url("../../assets/shapes_svg/feed/feedmain.svg");
    background-size:100% 100%;
}

.feed__info__label{
    background-image:url("../../assets/shapes_svg/feed/label.svg");
    background-size:100% 100%;
    width: fit-content;
    padding:10px 30px 10px 30px;
    color:#F2F8F0;
    position: relative;
    top:20px;
    /* left:40px; */
}

.feed__body{
    display: flex;
    flex-wrap:wrap;
    width:98%;
    padding:10px;
    padding-top:30px;
    background-image:url("../../assets/shapes_svg/feed/bubble.svg");
    background-size:100% 100%;
}

.feed__rowtitle{
    height: 250px;
    width: 238px;
    background-image:url("../../assets/shapes_svg/feed/body.svg") ;
    background-size:100% 100%;
}

.feed_rowitem {
    padding:30px;
    height:fit-content;
    background-image:url("../../assets/shapes_svg/feed/body.svg") ;
    background-size:100% 100%;
    margin:10px;
    color: #F2F8F0;
}