.course{
    margin-left:20px;
    width: 100%;
    font-family: 'SpockPro';
    display: flex;
    align-items: center;
    justify-content: center;
}
.course__item{
    width: 100%;
    height: 320px;
    padding-top:10px;
    background-image: url("../../assets/shapes_svg/course/body.svg");
    background-size:100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    color:white;
    text-align: center;    
}

.course__title {
    align-items: center;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    height:70%;
    overflow-y: hidden;
}


.course__title h4{
    /* margin-top:5px; */
    width:90%;
    padding:0px 10px 0px 10px;
    font-weight:200;
    margin-top:10px;
    font-size:15px;    
}

.course__title h3{
    width:90%;
    margin-top:10px;
    padding:0px 10px 0px 10px;
    font-size:20px;
}

.course__item img {
    width:15%;
    position: relative;
    /* align-self: flex-end; */
    margin:auto;
    /* left:36%;
    top:-10%; */
    
}

.course__button{
    cursor: pointer;
    /* width:110%; */
    background-image:url("../../assets/shapes_svg/course/button.svg");
    background-size:100% 100%;
    margin:0px 20px 30px 20px;
    padding:5px;
}

.course__option {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.course__option img:nth-child(1) {
    position:relative;
    top: -30px;
    left:-15px;
    cursor:pointer;
}

.course__option img:nth-child(2) {
    position:relative;
    top: -30px;
    left:-5px;
    cursor:pointer;
}



.courseadd{
    margin-left:20px;
    width: 215px;
    font-family: 'SpockPro';

}
.courseadd__item{
    width: 208px;
    height: 320px;
    background-image: url("../../assets/shapes_svg/course/bodyadd.svg");
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    color:white;
    text-align: center;
}

.courseadd__item h3 {
    margin-top:20px;

}

.courseadd__item h4{
    font-weight:200;
    font-size:15px;
    width:90%;
}
.courseadd__item img{
    width:42%;
    margin-top:auto;
    margin-bottom:30%;
    margin-left:auto;
    margin-right:auto;
}

.courseadd__option{
    display:flex;
    
}

.courseadd__option img {
    position:relative;
    top: -335px;
    
    margin-left:auto;
}

.suggestedcourse{
    margin-left:20px;
    width: 100%;
    font-family: 'SpockPro';
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .suggestedcourse__item:hover {
    border:1px solid rgba(0, 0, 0, 0.349);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.39);
} */

.suggestedcourse__wrapper {
    align-self:center ;
}

.suggestedcourse__wrapper > h4{
    position: relative;
    top:50%;
    padding:7px 15px 7px 15px;
    display:inline-block;
    background-image:url("../../assets/shapes_svg/education/hourlabel.svg");
    background-size:100% 100%;
    color:#f2f8f0;
    font-weight:300;
    font-style:italic;
    align-self: center;
    font-size:18px;
    z-index:100;
}

.feed__wrapper > h4 {
    background-image:url("../../assets/shapes_svg/feed/hourlabel.svg");
    background-size:100% 100%;
}

.suggestedcourse__item{
    width:100%;
    max-width: 232px;
    height: 330px;
    margin-top:10px;
    padding-top:10px;
    background-image: url("../../assets/shapes_svg/course/body.svg");
    background-repeat: no-repeat;
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    align-items: center;
    color:white;
    text-align: center; 
    position: relative;   
}

.suggestedcourse__item:hover{
    background-color: #00000017;
    border-radius:20px;
}

.selected__feed__body {
    background-color: #00000017;
    border-radius:20px;
}

.feed__item {
    background-image: url("../../assets/shapes_svg/feed/body.svg");
}

.suggestedcourse__title {
    align-items: center;
    justify-content: space-around;
    display: flex;
    /* height:100%; */
    height:80%;
    flex-direction: column;
}


.suggestedcourse__title h4{
    /* margin-top:5px; */
    width:90%;
    /* height:85%; */
    overflow-y:hidden;
    padding:0px 10px 0px 10px;
    font-weight:200;
    margin-top:10px;
    font-size:15px;
    transition: height 2000ms linear;
}

.suggestedcourse__title h3{
    width:90%;
    margin-top:10px;
    /* height:30%; */
    /* height:30%; */
    /* overflow-y:scroll; */
    padding:0px 10px 0px 10px;
    font-size:18px;
    
}

/* .hover:nth-child(1){
    height:0%;
    overflow-y:hidden;
}

.hover:nth-child(2){
    height:100%;

    transition: height 2000ms linear;
    overflow-y:scroll;
} */

.suggestedcourse__item img {
    width:15%;
    position: relative;
    /* align-self: flex-end; */
    /* left:36%;
    top:-10%; */
    
}

.suggestedcourse__option {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.suggestedcourse__option img:nth-child(1) {
    position:relative;
    top: -30px;
    left:-15px;
}

.suggestedcourse__option img:nth-child(2) {
    position:relative;
    top: -30px;
    left:-5px;
}

.suggestedcourse__notrelevant{
    display: flex;
}

.suggestedcourse__buttons {
    display: flex;
    /* flex-direction: column; */
    position: relative;
    top:-10px;
    justify-content: space-evenly;
    width:100%;
    margin-bottom:10px;
}

.suggestedcourse__buttons img{
    cursor: pointer;
    /* margin:0px 10px 0px 10px; */
}

.suggestedcourse__buttons .suggestedcourse__button{
    display: flex;
    flex-direction: column;
    font-size:14px;
    
}

.suggestedcourse__button{
    width:50%;
    margin-left:10px;
    margin-right:10px;
}

.suggestedcourse__button h4 {
    font-weight:200;
    font-style:italic;
    color:#F2F8F0;
    background-image:url("../../assets/shapes_svg/course/button.svg");
    background-size:100% 100%;
    padding:3px 7px 3px 7px;
    margin-top:3px;
    font-size:18px;
    
}

.feed__button h4 {
    background-image:url("../../assets/shapes_svg/feed/hourlabel.svg");
}

.suggestedcourse__button {
    /* width:100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.suggestedcourse__notrelevant .suggestedcourse__button {
    width:100%;
    padding-left:15px;
    padding-right:15px;
}



.suggestedcourse__checkmark__container{
    display: flex;
    position: relative;
    height:100%;
    top:8px;
    left:-45px;
    /* padding-left: 15px; */
    /* margin-top: auto;
    margin-bottom: auto; */
    cursor: pointer;
    font-size: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* align-self: center; */
    opacity:0.7;
}

.suggestedcourse__checkmark__container input {
    /* width:15px;
    height:15px; */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.suggestedcourse__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #f2f8f0;
}

/* On mouse-over, add a grey background color */
.suggestedcourse__checkmark__container:hover input ~ .suggestedcourse__checkmark {
    background-color: #f2f8f0;
  }
  
  /* When the checkbox is checked, add a blue background */
  .suggestedcourse__checkmark__container input:checked ~ .suggestedcourse__checkmark {
    background-color: #624D4F;

  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .suggestedcourse__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .suggestedcourse__checkmark__container input:checked ~ .suggestedcourse__checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .suggestedcourse__checkmark__container .suggestedcourse__checkmark:after {
    left: 5px;
    /* top: 5px; */
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } 
/* .suggestedcourse__button img {
    width:17px;
    height:17px;
} */

.education__module {
    margin-top:10px;
    background-image: url("../../assets/shapes_svg/education/modules.svg");
    color:#F2F8F0;
    width:200px;
    height:90px;
    margin-left:30px;
    padding:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-size:100% 100%;
    cursor: pointer;
}

.education__module h4 {
    font-weight:500;
    width:100%;
    display:inline;
    overflow-wrap:break-word;
    font-size:18px;
}