.react-multi-carousel-list {
    width:90%;
    z-index: 20;
}

.react-multi-carousel-list li {
    display: flex;
}

.carousel-button-group {
    cursor: pointer;
}