.companydetail__emission__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companydetail__emission__wrapper > img {
    width:80%;
    margin-top:30px;
    margin-bottom:20px;
}

.companydetail__emission__wrapper .companydetail__emission__title {
    width:35%;
    margin-top:0;
    margin-bottom:30px;
    background-image:url('./assets/shapes_svg/companydetail/emissiontitle.svg');
    background-size:100% 100%;
    padding:10px;
    text-align: center;
    color:rgb(70, 73, 126);
}

.companydetail__emission__title h4 {
    font-weight:500;
    font-size:18px;
}

.companydetail__emission__title h5 {
    font-weight:300;
    font-style:italic;
}

.companydetail__emission {
    display: flex;
    align-items: flex-start;
    width:92%;
    position: relative;
    left:-2%;
    justify-content: space-between;
    min-height:400px;
}

.companydetail__emission img:nth-child(2) {
    
}

.companydetail__emission div {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.companydetail__emissionco2{
    background-image:url('./assets/shapes_svg/companydetail/emissionco2.svg');
    background-size:100% 100%;
    height:55%;
    padding:54px;
}

.companydetail__scopeemission{
    background-image:url('./assets/shapes_svg/companydetail/scopeemission1.svg');
    background-size:100% 100%;
    height:70%;
    padding:30px;
}

.companydetail__scopeemission h4{
    margin-top:10px;
}

.companydetail__scopeemission2{
    background-image:url('./assets/shapes_svg/companydetail/scopeemission2.svg');
    background-size:100% 100%;
    height:75%;
    margin-top:200px;
}

.companydetail__scopeemission3{
    background-image:url('./assets/shapes_svg/companydetail/scopeemission3.svg');
    background-size:100% 100%;
    height:80%;
    padding-top:80px;
    padding-right:25px;
}

.companydetail__emission h3 {
    font-size:80px;
    font-weight:300;
    display: inline;
    font-style:italic;
}

.companydetail__emission h5 {
    font-size:30px;
    font-weight:300;
    display: inline;
    font-style:italic;
}

.companydetail__emission h4{
    width:100%;
    text-align: center;
    font-weight:300;
    font-size:18px;
}

.companydetail__emissionco2 h4{
    margin-left:30%;
    font-size:20px;
}

.companydetail__emissionco2 h3{
    position: relative;
    /* top:-40%; */
    left:-8%;
}

.companydetail__emissionenergy{
    background-image:url('./assets/shapes_svg/companydetail/emissionenergy.svg');
    background-size:100% 100%;
    height:75%;
    margin-top:100px;
}

.companydetail__emissionwater{
    background-image: url('./assets/shapes_svg/companydetail/emissionwater.svg');
    background-size:100% 100%;
    height:70%;
    padding-top:6.5%;
    padding-bottom:15px;
}

.companydetail__emissionwater h4{
    width:30%;
    text-align: right;
}

.companydetail__emissionwater h3{
    position: relative;
    top:-2%;
    left:-4%;
}

.product__emission .companydetail__emission__title{
    background-image:url('./assets/shapes_svg/productdetail/emissiontitle.svg');
    background-size:100% 100%;
}

.product__emission .companydetail__emissionco2{
    background-image:url('./assets/shapes_svg/productdetail/emissionco2.svg');
    background-size:100% 100%;
}

.product__emission .companydetail__emissionenergy{
    background-image:url('./assets/shapes_svg/productdetail/emissionenergy.svg');
    background-size:100% 100%;
}

.product__emission .companydetail__emissionwater{
    background-image:url('./assets/shapes_svg/productdetail/emissionwater.svg');
    background-size:100% 100%;
}

.product__emission .companydetail__scopeemission{
    background-image:url('./assets/shapes_svg/productdetail/scopeemission1.svg');
}

.product__emission .companydetail__scopeemission2{
    background-image:url('./assets/shapes_svg/productdetail/scopeemission2.svg');
}

.product__emission .companydetail__scopeemission3{
    background-image:url('./assets/shapes_svg/productdetail/scopeemission3.svg');
}

.product__emission .companydetail__scopeemission h4{
    width:100%;
}