.home__thirdpage{
    position:relative;
    top:-8px;
    width:100%;
    height:100vh;
    /* background-color:rgba(255, 146, 228, 0.63); */
    background-position:center;
    background-size:100% auto;  
    overflow-y: hidden;
    align-items: center;
    display:flex;
    flex-direction: column;  
    /* justify-content: center; */
}

.home__thirdpage__text{
    /* max-width:864px; */
    width:50vw;
    height:100%;
    font-family: 'SpockPro';
    font-weight:200;
    
    position:absolute;
    text-align:justify;
    /* background-image: url("../../assets/frontpages/shape_2.svg"); */
    /* background-size:100% 100%; */
    color:white;
    display: flex;
    justify-content: center;
    margin:0;   
}

.home__thirdpage__text h3{
    display:inline-block;
    font-size: 6.9vh;
    /* width:90%; */
    text-align: center;
    
}
.home__thirdpage__text h4{
    display:inline-block;
    font-weight:200;
    font-size:4.9vh;
    
}

.home__thirdpage__text h5{
    display:inline-block;
    font-weight:100;
    font-size:30px;
    margin-left:30px;
    font-style:italic;
    
}

.home__thirdpage__container{
    width:65%;
    /* margin-top: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__thirdpage__shape2{
    /* width:100%; */
    position: relative;
    left:-10px;
    width: 47vw;
    max-width:50vw;
    height: 100vh;
    /* height:100vh; */

}