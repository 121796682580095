.pac-target-input {
    background-image: url("./assets/shapes_svg/input/body2.svg");
    background-size: 100% 100%;
    border: none;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    height:30px;
    padding-left:10px;
    font-size:14px;
}

.pac-container {
    background-image: url("./assets/shapes_svg/productdetail/autocomplete.svg");
    background-size: 100% 100%;
    background-color: transparent;
    box-shadow: none;
}

.pac-item {
    background-color: transparent;
    color:black ;
    border: none;
}

.pac-matched {
    color:black;
}

.pac-item-query {

    color:black;
}

.pac-icon{
    color:black;
    background-image: none !important;
}


.pac-item:hover{
    background-color: rgb(216, 216, 216);
}