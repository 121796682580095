*{
  margin:0;
  padding: 0;
  scrollbar-width: thin;
}

:root {
  --normaltablesize: 100%;
}

:root {
  --company-green: #C4D4CC;
}

.w-10{
	width:10% !important;
}
.w-15{
	width:15% !important;
}
.w-20{
	width:20% !important;
}
.w-25{
	width:25% !important;
}
.w-30{
	width:30% !important;
}
.w-35{
	width:35% !important;
}
.w-40{
	width:40% !important;
}
.w-45{
	width:45% !important;
}
.w-60{
	width:60% !important;
}
.w-65{
	width:65% !important;
}
.w-70{
	width:70% !important;
}
.w-75{
	width:75% !important;
}
.w-80{
	width:80% !important;
}
.w-85{
	width:85% !important;
}
.w-90{
	width:90% !important;
}
.w-95{
	width:95% !important;
}


/* #darkCompany {
  fill:var(--company-green);
} */



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  margin-bottom:0px;
}

.h5, h5{
  font-size:1.1rem;
}

.h6,h6{
  font-size:0.9rem;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #F1F8EF; */
  /* background-color: transparent; */
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:20px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius:20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* input:-webkit-autofill::first-line {
  color: red;
  font-family: "Rubik"; 
  font-weight: bold;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-Regular.otf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-Thin.otf");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-Light.otf");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-UltraLight.otf");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-Bold.otf");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-Black.otf");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-ThinItalic.otf");
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-UltraLightItalic.otf");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-LightItalic.otf");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: "SpockPro";
  src: url("./assets/fonts/Spock Pro/SpockPro-RegularItalic.otf");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


.harlow {
  font-family: "myFirstFont";
}

.nav-link{
  text-decoration: none;
  padding:0;
}


.bubble__absolute{
  width:100%;
  position:absolute;
  padding-top:5%;
  display:flex;
  align-items:center;
  flex-direction:column;
  height:700vh;
  overflow-y:hidden;
  z-index:0;
}

.modaladdlocation__question {
  margin-top:10px;
  display:flex;
}

.modaladdlocation__question h4 {
  font-weight:300;
  font-size:0.9rem; 
}

.modaladdlocation__question input {
  font-size:0.9rem !important;
}

.modaladdlocation__question h3 {
  width:20%;
  align-self: center;
}

.companydetail__option__info{
  display: flex;
  margin-top:20px;
  width:100%;
}

.companydetail__option__info div{
  width:10%;
}

.row__error__message {
  background-image: none !important;
  color:red !important;
  
}

.row__error__message h4 {
  background-color: transparent !important;
}

.row__error__message .modaladdlocation__question {
  padding:5px !important;
  padding-left: 10px !important;
}

.companydetail__title__container{
  display: flex;
  width:90%;
  /* max-width:fit-content; */
  height:50%;
  align-items: center;    
  justify-content: center;
}

.companydetail__title__style2 {
  background-image:url("./assets/shapes_svg/companydetail/title7.svg") !important;
  background-size:100% 100%;
  padding:20px;
  width:100%; 
}

.companydetail__title__container img{
  height:9vh;
  width:7vw;
}

.companydetail__title__style2 h5{
  background-image:none !important;
  font-weight:500 !important;
  padding:0 !important;
  color: #F2F8F0;
}

.companydetail__title__style2 h6{
  color: #F2F8F0;
  text-align: justify;
  font-weight:300;
}

.companydetail__group {
  display: flex;
  width: 100%;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@media print {
  @page {
    size: A4 potrait;
  }
}

@media print {
  /* h5 {
      font-size:300px !important;
  } */
}

@page {
  /* size: auto; */
  /* margin: 20mm; */
}

.me-6 {
  margin-right:4.2rem !important;
}

.ms-6 {
  margin-left:4.9rem !important;
}

.mb-10 {
  margin-bottom:10rem !important;
}

.font__darker{
  color:rgb(70, 73, 126);
}

.tip__scroll{
  background-image:url("./assets/shapes_svg/tip/body.svg");
  background-size:100% 100%;
  /* width:20%; */
  padding-top:2px;
  padding-bottom:2px;
  max-width:150px;
  margin-top:4px;
  margin-left:20px;
  transition: opacity 3s;
}

.tip__scroll h5 {
  color:#1B3764;
}

.tip__scroll h6{
  color:#1B3764;
  margin: auto;
  margin-top: 10px;
  font-size:14px;
  width:100%;
  font-weight:300;
  text-align: center;
  margin-bottom:15px;
}

.react-datepicker-popper{
  z-index:30 !important;
}